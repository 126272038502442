import React from 'react';
import './VideoItem.scss';

const VideoItem = ({ video, onVideoSelect }) => (
    <div onClick={() => onVideoSelect(video)} aria-hidden="true" className="video-item item">
        <img className="ui image" src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
        <div className="content">
            <p className="header">{video.snippet.title}</p>
        </div>
    </div>
);

export default VideoItem;
