import React from 'react';
import './VideoList.scss';

import VideoItem from '../VideoItem/VideoItem';

const VideoList = ({ videos, onVideoSelect }) => {
    const renderedList = videos.map((video) => (
        <VideoItem key={video.id.videoId} onVideoSelect={onVideoSelect} video={video} />
    ));

    return <div className="video-list ui relaxed divided list">{renderedList}</div>;
};

export default VideoList;
